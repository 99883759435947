import React from 'react';
import { Link } from 'react-router-dom';
import './StepsList.scss';

function StepItem({
  children = null,
  to = '',
  stepNum = null,
  stepActive = false,
  stepPassed = false,
}) {
  const handleClick = event => {
    if (!stepPassed) {
      event.preventDefault();
    }
  };

  let className = 'StepItem';
  className += stepActive ? ' active-step' : '';
  className += stepPassed ? ' finished-step' : '';
  className += !stepPassed && !stepActive ? ' inactive-step' : '';

  return (
    <li className={className}>
      <Link to={to} onClick={handleClick}>
        {children}
      </Link>
    </li>
  );
}

function StepsList({ currentStep = null }) {
  return (
    <ul className="StepsList">
      <StepItem
        to="/returns"
        stepNum={1}
        stepActive={currentStep === 1}
        stepPassed={currentStep > 1}
      >
        Välj varumärke och produkter
      </StepItem>
      <StepItem to="/cart" stepNum={2} stepActive={currentStep === 2} stepPassed={currentStep > 2}>
        Granska din returkorg
      </StepItem>
      <StepItem
        to="/submit"
        stepNum={3}
        stepActive={currentStep === 3}
        stepPassed={currentStep > 3}
      >
        {' '}
        Fyll i dina uppgifter
      </StepItem>
    </ul>
  );
}

export default StepsList;
