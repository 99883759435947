import React from 'react';
import { Link, NavLink, withRouter, useLocation } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import { useBanner } from '../contexts/BannerContext';
import logo from '../images/skruf_logo.svg';
import './AppHeader.scss';
import LanguageSelector from './LanguageSelector.js';

const appIsClosed = false;

function NavItem(props) {
  const { isNavLink } = props;
  const { wasAdded, items, getItemCount } = useCart();

  const navClasses = ['NavItem'];

  if (props.isCart && wasAdded) {
    navClasses.push('item-added');
  }

  if (props.isCart && items.length > 0) {
    navClasses.push('cart-is-not-empty');
  }

  if (isNavLink) {
    return (
      <li className={navClasses.join(' ')}>
        <NavLink to={props.to} exact={props.exact}>
          {props.children}
          {props.isCart && <span className="NavItem-cartcount">{getItemCount()}</span>}
        </NavLink>
      </li>
    );
  } else {
    return (
      <li className="NavItem">
        <Link to={props.to}>{props.children}</Link>
      </li>
    );
  }
}

function AppHeader(props) {
  const { bannerVisible } = useBanner();
  let location = useLocation();
  return (
    <header
      className={`AppHeader${bannerVisible && location.pathname === '/' ? ' announcement' : ''}`}
    >
      <div className="wrapper">
        <Link to="/" className="AppLogo">
          <img src={logo} alt={'skruf symbol'} className="AppLogo-symbol" />
        </Link>
        <LanguageSelector simple={true} />
        <nav className="AppNavigation">
          <ul className="AppMenu">
            {!appIsClosed && (
              <>
                <NavItem to="/cart" exact={true} isNavLink={true} isCart={true}>
                  <label className="hide-in-mobile">Returkorg</label>
                  <svg className="icon icon-bag">
                    <use xlinkHref="#icon-bag"></use>
                  </svg>
                </NavItem>
              </>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default withRouter(AppHeader);
