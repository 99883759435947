import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";
import { useCart } from "../contexts/CartContext";
import Loader from "./shared/Loader";
import ProductItem from "./ProductItem";

import "./ProductList.scss";
// Chiild component for Brands, handles the filter for the ProductList component
function FilterItem(props) {
  const { setFilter, currentFilter, item } = props;
  return (
    <li
      key={item.id}
      className={currentFilter === item.id ? "FilterItem active" : "FilterItem"}
    >
      <button
        onClick={(event) => {
          event.preventDefault();
          setFilter(item.id);
        }}
      >
        {item.name}
      </button>
    </li>
  );
}

FilterItem.propTypes = {
  setFilter: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};



function ProductList() {
  const [stateProducts, setProducts] = useState([]);
  const [stateBrands, setBrands] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [filter, setFilter] = useState(2); // 2 = skruf brand
  const { items } = useCart();

  // After first render, fetch brands and products to populate in the states
  useEffect(() => {
    function fetchBrands() {
      const { REACT_APP_API_URL } = process.env;
      const endpoint = `${REACT_APP_API_URL}/wp/v2/trademark?hide_empty=1&orderby=menu_order`;
      console.log(
        `Fetching brands from ${endpoint}`
      );
      return axios.get(endpoint);
    }

    
    function fetchData() {
      const { REACT_APP_API_URL } = process.env;
      const endpoint = `${REACT_APP_API_URL}/wp/v2/products?_embed&per_page=100&orderby=menu_order`;
      console.log(
        `Fetching products from ${endpoint}`
      );
      return axios.get(endpoint);
    }

    setIsLoading(true);
    setIsError(false);

    try {
      axios.all([fetchBrands(), fetchData()]).then(
        axios.spread((brands, products) => {
          setProducts(products.data);
          setBrands(brands.data);
          setIsLoading(false);
        })
      );
    } catch (error) {
      console.error(error);
      setIsError(true);
      setIsLoading(false);
    }
  }, []);

  // Map through the filter items to create the brand filter components
  const FilterItems =
    stateBrands.length > 0
      ? stateBrands.map((item) => {
          return (
            <FilterItem
              key={item.id}
              item={item}
              setFilter={setFilter}
              currentFilter={filter}
            />
          );
        })
      : null;

  // Map through the product items to create the product item components for the cart
  const ProductItems =
    stateProducts.length > 0
      ? stateProducts
          .filter((item) => {
            return filter === "*" || item.trademark.includes(filter);
          })
          .map((item) => {
            return (
              <ProductItem key={item.id} itemData={item}>
                <h2>
                  <strong>{item.product_group}</strong>
                  {item.product_name}
                </h2>
              </ProductItem>
            );
          })
      : null;

  return (
    <>
      {isError && <h2>Det skedde ett fel i hämtningen av produkterna</h2>}
      {isLoading && <Loader />}
      {FilterItems && ProductItems ? (
        <>
          <ul className="FilterList">{FilterItems}</ul>
          <div className="ProductList">{ProductItems}</div>
          <footer className="ProductList-footer">
            <div className="flex-center">
            {items.length > 0 ? (
              <Link to="/cart" className="primary-button">
                Se din returkorg
                <svg className="icon icon-bag">
                  <use xlinkHref="#icon-bag"></use>
                </svg>
              </Link>
            ) : (
              <>
                <h2>Din returkorg är tom</h2>
              </>
            )}
            </div>
          </footer>
        </>
      ) : null}
    </>
  );
}

export default ProductList;
