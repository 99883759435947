import React from 'react';
import { Link } from 'react-router-dom';
import LinkButton from '../components/LinkButton';
import { useBanner } from '../contexts/BannerContext';
import './IndexPage.scss';

function AnnouncementBanner({ data }) {
  const { title } = data;
  return (
    <>
      <Link to="/page/announcement" className="AnnouncementBanner-anchor">
        <div id="AnnouncementBanner">
          <h4 className="Announcement-Title">
            {title.rendered}
            <svg className="icon icon-arrow-right">
              <use xlinkHref="#icon-arrow-right"></use>
            </svg>
          </h4>
        </div>
      </Link>
    </>
  );
}

function IndexPage() {
  const { announcement } = useBanner();

  return (
    <>
      {announcement && <AnnouncementBanner data={announcement} />}
      <div className="wrapper">
        <article className="text-flow content">
          <h1>Välkommen!</h1>
          <p className="preamble">Denna sida är endast till för returer från återförsäljare.</p>
          <p>På denna webbplats kan du som återförsäljare göra dina produktreturer.</p>
          <p className="preamble">Returerna görs i tre enkla steg:</p>
          <ol className="guide-list">
            <li>Välj varumärke och ange antal produkter (minst 50 dosor)</li>
            <li>Granska din returkorg och ändra om nödvändigt</li>
            <li>Fyll i dina uppgifter och invänta återkoppling från Skruf Snus</li>
          </ol>
        </article>
        <div className="flex-center">
          <LinkButton to="/returns" className="secondary-button">
            Till returer
          </LinkButton>
        </div>
      </div>
    </>
  );
}

export default IndexPage;
