import React from 'react';
import ProductList from '../components/ProductList';
import StepsList from '../components/StepsList';

// import './Index.scss';

function Products() {
  return (
    <div className="wrapper">
      <>
        <StepsList currentStep={1} />
        <ProductList />
      </>
    </div>
  );
}

export default Products;
