import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import './LanguageSelector.scss';

// Include the language info as an array collection
const languages = [
  { slug: 'en', name: 'Engelska', shortName: 'ENG', to: '/page/english' },
  { slug: 'ar', name: 'Arabiska', shortName: 'ARA', to: '/page/arabic' },
];

export default function Component(props) {
  const isMobileSized = useMediaQuery({ query: `(max-width: 640px)` });

  return (
    <div className="LanguageSelector">
      {languages.map(({ name, shortName, to }, index) => (
        <NavLink key={index} to={to}>
          {isMobileSized ? shortName : name}
        </NavLink>
      ))}
    </div>
  );
}
