import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LinkButton from '../components/LinkButton';
import StepsList from '../components/StepsList';
import Loader from '../components/shared/Loader';
import { useCart } from '../contexts/CartContext';
import './CheckoutForm.scss';

function FormDescription() {
  return (
    <>
      <div className="infobox">
        <h2>Returinformation</h2>
        <p>Vi tar emot returer på minst 50 dosor eller mer.</p>
        <p>
          Efter ni fyllt i informationen nedan kommer vi att skicka följande till er angivna
          mailadress:
        </p>
        <ul>
          <li>
            Följesedel på er retur – kontrollera att följesedeln stämmer överens med det antalet
            dosor ni vill skicka tillbaka
          </li>
          <li>Fraktsedel – Skruf snus står för frakten</li>
          <li>Instruktioner för att boka upphämtning</li>
        </ul>
        <p>
          När vi har mottagit er retur och lagret har kontrollräknat den kommer vi att betala ut en
          kredit till angivet konto för utbetalning.
        </p>
        <p>
          Returer hanteras allt eftersom de skickas in. På grund av hög arbetsbelastning på vårt
          lager dröjer det något längre tid än vanligt innan er retur är kontrollräknad. Lagret
          jobbar på så fort de kan och vi är tacksamma för ditt tålamod.
        </p>
      </div>
      <h1>Fyll i dina uppgifter</h1>
      <p>Fält märkta * är obligatoriska</p>
    </>
  );
}

const Form = props => {
  const formik = useFormik({
    initialValues: {
      storeNumber: '',
      storeName: '',
      organisationNumber: '',
      payoutDestination: '',
      payoutDestinationNumber: '',
      postageSlips: '',
      slipDestination: '',
      email: '',
      ageCheck: '',
    },
    /*
    If we have error handling in Formik it checks error on every field with every change.
    With the settings below it will only happen onSubmit.
    validateOnChange: false,
    validateOnBlur: false,
    */
    onSubmit: values => {
      console.log('1.', values);
      props.handleSubmit(values);
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} className="CartList-form simple">
      <label htmlFor="storeNumber">Swedish Match kundnummer eller butiksnummer *</label>
      <input
        type="text"
        id="storeNumber"
        name="storeNumber"
        required
        {...formik.getFieldProps('storeNumber')}
      />

      <label htmlFor="storeName">Butiksnamn *</label>
      <input
        type="text"
        id="storeName"
        name="storeName"
        required
        {...formik.getFieldProps('storeName')}
      />

      <label htmlFor="organisationNumber">Organisationsnummer *</label>
      <input
        type="text"
        id="organisationNumber"
        name="organisationNumber"
        required
        {...formik.getFieldProps('organisationNumber')}
      />

      <label>Konto för utbetalning *</label>
      <div className="flex-vertical-center">
        <input
          type="radio"
          className="styled-radio"
          value="Bankgiro"
          name="payoutDestination"
          id="payoutDestination_1"
          checked={formik.values.payoutDestination === 'Bankgiro'}
          onChange={formik.handleChange}
          required
        />
        <label className="styled-radio" htmlFor="payoutDestination_1">
          <span>Bankgiro</span>
        </label>
        <input
          type="radio"
          className="styled-radio"
          value="Plusgiro"
          name="payoutDestination"
          id="payoutDestination_2"
          onChange={formik.handleChange}
          checked={formik.values.payoutDestination === 'Plusgiro'}
          required
        />
        <label className="styled-radio" htmlFor="payoutDestination_2">
          <span>Plusgiro</span>
        </label>
        <input
          type="radio"
          className="styled-radio"
          value="Annat/Bankkonto"
          name="payoutDestination"
          id="payoutDestination_3"
          onChange={formik.handleChange}
          checked={formik.values.payoutDestination === 'Annat/Bankkonto'}
          required
        />
        <label className="styled-radio" htmlFor="payoutDestination_3">
          <span>Annat/Bankkonto</span>
        </label>
      </div>

      <label htmlFor="payoutDestinationNumber">Nr *</label>
      <input
        type="text"
        id="payoutDestinationNumber"
        name="payoutDestinationNumber"
        {...formik.getFieldProps('payoutDestinationNumber')}
      />

      <label htmlFor="postageSlips">Antal fraktsedlar</label>
      <input
        type="text"
        id="postageSlips"
        name="postageSlips"
        {...formik.getFieldProps('postageSlips')}
      />

      <label>Hur vill du ha fraktsedlarna? *</label>
      <div className="flex-vertical-center">
        <input
          className="styled-radio"
          type="radio"
          name="slipDestination"
          id="slipDestination_1"
          value="E-post"
          required
          onChange={formik.handleChange}
          checked={formik.values.slipDestination === 'E-post'}
        />
        <label className="styled-radio" htmlFor="slipDestination_1">
          <span>E-post</span>
        </label>
        <input
          className="styled-radio"
          type="radio"
          name="slipDestination"
          id="slipDestination_2"
          value="Post"
          required
          onChange={formik.handleChange}
          checked={formik.values.slipDestination === 'Post'}
        />
        <label className="styled-radio" htmlFor="slipDestination_2">
          <span>Post</span>
        </label>
      </div>

      <label htmlFor="email">E-post *</label>
      <input type="email" id="email" name="email" required {...formik.getFieldProps('email')} />

      <footer className="form-footer flex-center">
        {!props.isLoading && (
          <>
            <LinkButton disabled={props.isLoading} className="outlined-button" to="/cart">
              Tillbaka
            </LinkButton>
            <button disabled={props.isLoading} className="primary-button" type="submit">
              Skicka
            </button>
          </>
        )}
        {props.isLoading && <Loader />}
      </footer>
    </form>
  );
};

function CheckoutForm() {
  // Which contexts to consume
  const { items, destroyCart, getItemCount } = useCart();

  // Several states
  // const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  /**
   * Function which handles the cart list form submit and post request to the API.
   * @param {*} event
   */
  function handleSubmit(values) {
    // Prevent page reload upon submit

    setIsLoading(true);

    const itemData = items.map(item => {
      return {
        productId: item.id,
        productNr: item.product_nr,
        count: item.count,
      };
    });

    const { REACT_APP_API_URL, REACT_APP_URL } = process.env;
    async function postItems() {
      try {
        const endpoint = `${REACT_APP_API_URL}/skrufreturer/v1/returns`;

        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            Origin: REACT_APP_URL,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            details: values,
            items: itemData,
          }),
        });

        const data = await response.json();

        if (data) {
          setIsLoading(false);
          setIsSubmitted(true);
          destroyCart();
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        // setIsError(true);
      }
    }

    // Post the items data towards the API
    postItems();
  }

  useEffect(() => {
    if (isSubmitted && !isLoading) {
      destroyCart();
    }
  }, [isSubmitted, destroyCart, isLoading]);

  const itemsInCart = getItemCount();

  if (isSubmitted && !isLoading) {
    return (
      <div className="wrapper">
        <div className="text-flow content">
          <h2>Tack!</h2>
          <p className="preamble">
            <Link to="/">Tillbaka till startsidan</Link>
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <StepsList currentStep={3} />
      {itemsInCart <= 49 && (
        <>
          <h2 className="PageTitle">Fel i din returkorg</h2>
          <p className="preamble center-block">
            Vi tar emot returer på minst 50 dosor eller mer.
            <br /> Se till att uppdatera antalet dosor i din returkorg för att gå vidare.
          </p>
          <Link to="/cart" className="center-block">
            Uppdatera din returkorg
          </Link>
        </>
      )}
      {itemsInCart >= 50 && (
        <div className="wrapper submitReturn">
          <Link to="/cart" className="center-block">
            Uppdatera din returkorg
          </Link>
          <div className="content">
            <FormDescription />
            <Form handleSubmit={handleSubmit} isLoading={isLoading} />
          </div>
        </div>
      )}
    </>
  );
}

export default CheckoutForm;
