import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import './App.scss';
import AppHeader from './components/AppHeader';
import CartProvider from './contexts/CartContext';
import BannerProvider from './contexts/BannerContext';
import IndexPage from './pages/IndexPage';
import NoMatch from './pages/NoMatch';
import Page from './pages/Page';
import Products from './pages/Products';
import Cart from './pages/Cart';
import CheckoutForm from './pages/CheckoutForm';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

/**
 * Thw app main component, harbors all contexts, routes and usual components.
 */
function App(props) {
  return (
    <CartProvider>
      <BannerProvider>
        <div className="App">
          <Router>
            <ScrollToTop />
            <AppHeader />
            <Switch>
              <Route path="/" component={IndexPage} exact />
              <Route path="/returns" component={Products} exact />
              <Route path="/submit" component={CheckoutForm} exact />
              <Route path="/cart" component={Cart} exact />
              <Route path="/page/:pagename" component={Page} />
              <Route component={NoMatch} />
            </Switch>
          </Router>
        </div>
      </BannerProvider>
    </CartProvider>
  );
}

export default App;
