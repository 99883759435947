import React from 'react';
import { Link } from 'react-router-dom';

// When an route is not found, render this component
function NoMatch() {
  return (
    <div className="wrapper">
    <div className="NoMatch">
      <h2>Sidan du efterfråga kunde inte hittas</h2>
      <p>
        <Link to="/">Till startsidan</Link>
      </p>
    </div>
    </div>
  );
}

export default NoMatch;
