import React from 'react';

/**
 * Component for responsive img elements for the products.
 */
function ProductImage(props) {
  const { targetSizes, sizes } = props;

  // Default mediaQueries for the image sizes
  const mediaQueries = [
    {
      imgSize: 'thumbnail',
      maxWidth: 500
    },
    {
      imgSize: 'medium',
      maxWidth: 750
    },
    {
      imgSize: 'medium_large',
      maxWidth: 1000
    },
    {
      imgSize: 'medium_large',
      maxWidth: 1250
    }
  ];

  // Map through the sizes var and create the sizes attribute data
  const imgSizes =
    Object.entries(sizes)
      .filter(item => {
        return targetSizes.includes(item[0]);
      })
      .map((item, index) => {
        const imgData = item[1];
        const mediaQuery = mediaQueries.find(obj => {
          return obj.imgSize === item[0];
        });

        const maxWidth = mediaQuery.maxWidth
          ? mediaQuery.maxWidth
          : imgData.width;

        return targetSizes.length === index + 1
          ? `${imgData.width}px`
          : `(max-width: ${maxWidth}px) ${imgData.width}px`;
      })
      .join(',') || null;

  // Map through the sizes var and create the srcset attribute data
  const imgSrcset =
    Object.entries(sizes)
      .filter(item => {
        const size = item[0];
        return targetSizes.includes(size);
      })
      .map(item => {
        const data = item[1];
        return `${data.source_url} ${data.width}w`;
      })
      .join(',') || null;

  // Render the ProductImage component
  return (
    <div className="ProductImage">
      <img
        src={props.src}
        sizes={imgSizes}
        srcSet={imgSrcset}
        alt={props.alt}
      />
    </div>
  );
}

export default ProductImage;
