import React, { createContext, useContext, useEffect, useState } from 'react';

export const BannerContext = createContext([]);

export default function BannerProvider(props) {
  const [announcement, setAnnouncement] = useState(null);
  const [bannerVisible, setBannerVisible] = useState(false);

  const values = {
    announcement,
    setAnnouncement,
    bannerVisible,
    setBannerVisible,
  };

  // When state changes, handle the sessionStorage
  useEffect(() => {
    async function fetchAnnouncement() {
      const { REACT_APP_API_URL } = process.env;
      try {
        const response = await fetch(`${REACT_APP_API_URL}/wp/v2/pages/?slug=announcement`);
        const data = await response.json();
        return data;
      } catch (error) {
        console.error(error);
        return false;
      }
    }

    // Fetch the page data
    // Array is returned from WordPress due to requesting the page using slug and not page id like: /pages/${page_id}
    fetchAnnouncement().then(pages => {
      if (pages.length > 0) {
        setAnnouncement(pages[0]);
        setBannerVisible(true);
      }
    });
  }, [setAnnouncement, setBannerVisible]);

  return <BannerContext.Provider value={values}>{props.children}</BannerContext.Provider>;
}

export const BannerConsumer = BannerContext.Consumer;
export const useBanner = () => useContext(BannerContext);
