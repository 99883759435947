// Simple child component for adding / removing products from the cart context
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useCart } from '../contexts/CartContext';
import ProductImage from './shared/ProductImage';

function ProductItem(props) {
  const [internalCount, setInternalCount] = useState(0);
  const { itemData, children } = props;
  const { add, amountInCart } = useCart();

  const [attachment] = itemData['_embedded']['wp:featuredmedia'] || [];
  const imgSizes = ['thumbnail', 'medium', 'medium_large'];

  const min = 0;
  const max = 9999;

  // Apply min & max rules to the integer
  function getFixedCount(integer) {
    const count = parseInt(integer, 10);
    if (count > max) {
      return max;
    } else if (count < min) {
      return min;
    } else {
      return count;
    }
  }

  return (
    <div className="ProductItem">
      <div className="item-wrapper">
        {attachment && attachment.media_details && (
          <ProductImage
            alt={attachment.alt_text}
            src={attachment.media_details.sizes.thumbnail.source_url || ''}
            sizes={attachment.media_details.sizes}
            targetSizes={imgSizes}
          />
        )}
        {children}

        <div className="ProductItem-fields">
          <label htmlFor={`CartItem-Amount-Private-${props.itemData.id}`}>Antal:</label>

          <input
            type="number"
            value={internalCount || ''}
            placeholder={0}
            min={min}
            max={max}
            name={`CartItem-Amount-Private-${props.itemData.id}`}
            id={`CartItem-Amount-Private-${props.itemData.id}`}
            onChange={event => {
              const count = getFixedCount(event.target.value || 0);
              setInternalCount(count);
            }}
          />

          <button
            className="ProductItem-button button-cart-remove"
            onClick={event => {
              let value = Math.max(Number(0), Math.min(Number(9999), Number(internalCount - 1)));
              setInternalCount(value);
            }}
          >
            <svg className="icon icon-minus bold">
              <use xlinkHref="#icon-minus"></use>
            </svg>
          </button>

          <button
            className="ProductItem-button button-cart-add"
            onClick={event => {
              let value = Math.max(Number(0), Math.min(Number(9999), Number(internalCount + 1)));
              setInternalCount(value);
            }}
          >
            <svg className="icon icon-plus bold">
              <use xlinkHref="#icon-plus"></use>
            </svg>
          </button>

          <button
            className="ProductItem-button button-cart-goto"
            onClick={event => {
              // Do not add to cart when internalCount is 0
              if (internalCount <= 0) {
                return alert('Fyll i antal före du lägger till i returkorgen.');
              }

              // Add the item to code cart
              add(itemData, internalCount);
              setInternalCount(0);
            }}
          >
            <span className="NavItem-cartcount">{amountInCart(itemData)}</span>
            <svg className="icon icon-bag">
              <use xlinkHref="#icon-bag"></use>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

ProductItem.propTypes = {
  children: PropTypes.element,
  itemData: PropTypes.object.isRequired,
};

export default ProductItem;
