import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useCart } from '../contexts/CartContext';
import ProductImage from './shared/ProductImage';

/**
 * Child component for the cart list, renders a single cart item.
 */
function CartItem(props) {
  const { itemData } = props;
  const { count } = itemData;
  const { remove, setCount } = useCart();
  const [internalCount, setInternalCount] = useState(count);

  // Some product img media
  const [attachment] = itemData['_embedded']['wp:featuredmedia'] || [];
  const imgSizes = ['thumbnail', 'medium', 'medium_large'];

  // Return the render of the cart item (this should probably be divided into several child components)
  return (
    <div className={`CartItem`}>
      {attachment && attachment.media_details && (
        <ProductImage
          alt={attachment.alt_text}
          src={attachment.media_details.sizes.thumbnail.source_url || ''}
          sizes={attachment.media_details.sizes}
          targetSizes={imgSizes}
        />
      )}
      <div className="CartItem-fields">
        <h2 className="CartItem-title">
          <strong>{props.itemGroup}</strong>
          {props.itemName}
        </h2>

        <div className="CartItem-field">
          <div className="CartItem-amount-inputs">
            <label htmlFor={`CartItem-Amount-Private-${props.itemData.id}`} className="simple-only">
              Antal dosor:
            </label>
            <input
              type="number"
              value={internalCount || ''}
              placeholder={0}
              min={0}
              max={9999}
              name={`CartItem-Amount-Private-${props.itemData.id}`}
              id={`CartItem-Amount-Private-${props.itemData.id}`}
              onChange={event => {
                let tempCount = parseInt(event.target.value, 10) || '';
                tempCount = tempCount > 9999 ? 9999 : tempCount;

                setInternalCount(tempCount);
                setCount(itemData, typeof tempCount === 'number' && tempCount > 0 ? tempCount : 0);
              }}
            />
          </div>
        </div>

        <button
          className="CartItem-remove-button"
          title="Ta bort produkten från returkorgen"
          onClick={event => {
            remove(props.itemData);
            event.preventDefault();
          }}
        >
          <svg className="icon icon-cross2">
            <use xlinkHref="#icon-cross2"></use>
          </svg>
        </button>
      </div>
    </div>
  );
}

CartItem.propTypes = {
  itemData: PropTypes.object.isRequired,
  itemGroup: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  itemId: PropTypes.number.isRequired,
};

export default CartItem;
