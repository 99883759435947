import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import CartItem from '../components/CartItem';
import LinkButton from '../components/LinkButton';
import { useCart } from '../contexts/CartContext';
import './Checkout.scss';

/**
 * Renders the whole CartList along with cartitems etc.
 */
function Checkout(props) {
  // Which contexts to consume
  const { items } = useCart();
  const sorted = getSortedItemsByGroup();

  /**
   * Function sorts cart array into object with properties by product group
   * @returns object
   */
  function getSortedItemsByGroup() {
    return items.reduce((groups, item) => {
      const pGroup = item.product_group;

      if (!groups[pGroup]) groups[pGroup] = [];

      groups[pGroup].push(item);
      return groups;
    }, {});
  }

  return (
    <div className="cartPage">
      {items.length <= 0 ? (
        <>
          <h2 className="PageTitle">Din returrkorg är tom</h2>
          <Link to="/returns" className="center-block">
            Tillbaka till produkterna
          </Link>
        </>
      ) : (
        <>
          <h2 className="PageTitle">Din returkorg</h2>
          <div className="CartList">
            {Object.keys(sorted).map(function (key, index) {
              let items = sorted[key];

              return (
                <Fragment key={key}>
                  {items.map(item => {
                    return (
                      <CartItem
                        simple={props.simple}
                        key={item.id}
                        itemGroup={item.product_group}
                        itemName={item.product_name}
                        itemId={item.id}
                        itemData={item}
                      />
                    );
                  })}
                </Fragment>
              );
            })}
          </div>
          <footer className="CartList-footer">
            <div className="flex-center">
              <LinkButton className="outlined-button" to="/returns">
                Tillbaka till produkterna
              </LinkButton>
              <LinkButton type="submit" className="secondary-button" to="/submit">
                Nästa steg
              </LinkButton>
            </div>
          </footer>
        </>
      )}
    </div>
  );
}

export default Checkout;
