import React from 'react';
//import ProductList from '../components/ProductList';
import Checkout from '../components/Checkout';
//import FeedbackList from '../components/FeedbackList';
import StepsList from '../components/StepsList';

function Page() {
  return (
    <>
      <StepsList currentStep={2} />
      <div className="wrapper submitReturn">
        <Checkout />
      </div>
    </>
  );
}

export default Page;
