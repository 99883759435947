import React from 'react';

import './Loader.scss';

function Loader(props) {
  return (
    <div className="loader">
      <div className="loader-inner ball-scale-multiple">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Loader;
