import React, { useEffect, useState } from 'react';
import Loader from '../components/shared/Loader';
import NoMatch from './NoMatch';

import './Page.scss';

// Temporary dummy content
function PageContent({ content }) {
  return (
    <div className="text-flow" dangerouslySetInnerHTML={{ __html: content.rendered || '' }}></div>
  );
}

// Page component will need to fetch Page contents from WP based on pagename prop
function Page({ match }) {
  // Page component states
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(null);

  const { pagename } = match.params;

  // Upon mount, fetch the page contents
  useEffect(() => {
    async function fetchPage(slug) {
      const { REACT_APP_API_URL } = process.env;
      try {
        const response = await fetch(`${REACT_APP_API_URL}/wp/v2/pages/?slug=${slug}`);
        const data = await response.json();
        return data;
      } catch (error) {
        console.error(error);
        return false;
      }
    }

    // If pagename is undefined by any means, skip fetch
    if (typeof pagename === 'undefined') {
      setLoading(false);
      return;
    }

    // Fetch the page data
    // Array is returned from WordPress due to requesting the page using slug and not page id like: /pages/${page_id}
    fetchPage(pagename).then(pages => {
      if (pages && pages.length > 0) {
        setPage(pages[0]);
      }

      setLoading(false);
    });
  }, [setPage, setLoading, pagename]);

  if (loading) {
    return <Loader />;
  } else if (page && !loading) {
    return (
      <div className={pagename === 'arabic' ? 'wrapper page in-arabic' : 'wrapper page'}>
        <div className="text-flow content">
          <h1
            className="page-title"
            dangerouslySetInnerHTML={{ __html: page.title.rendered || '' }}
          />
          <PageContent content={page.content || ''} />
        </div>
      </div>
    );
  } else {
    // Page could not be found, use NoMatch component
    return <NoMatch />;
  }
}

export default Page;
